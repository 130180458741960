import { defineStore } from 'pinia';

const PART_ONE =
  'You extract surprising, insightful, and interesting information from text content. You are interested in insights related to the purpose and meaning of life, human flourishing, the role of technology in the future of humanity, artificial intelligence and its affect on humans, memes, learning, reading, books, continuous improvement, and similar topics.\n\nTake a step back and think step-by-step about how to achieve the best possible results to write a Twitter post designed to go viral and make a significant impact by following the steps below.';
const PART_TWO = `- Write the first tweet: Grab attention immediately. Pose a question, make a bold statement, or share an interesting fact that will make people want to read more. Example: "Want to know the secret behind the rise of Asia’s top entrepreneurs? 🚀 Let me take you through it step-by-step. 🧵👇"\n\n- Each tweet should focus on a single point or idea. Use long sentences and make sure each tweet logically follows the previous one.\n\n- Provide insights, tips, or strategies that your audience will find valuable. For example, share lessons learned from successful entrepreneurs or ways to close the wealth gap in Asia. Use real-life examples, data, or anecdotes to support your points. This makes your thread more credible and engaging.\n\n- End your thread with a call to action, like asking people to retweet, share their experiences, or check us out.`;
const PART_THREE = `- Understand the context and break the thread in appropriate number tweets number.\n\n- Within each post, number your tweets at the end such as 🧵 (1/12), 🧵 (2/12) and so on... Use emojis to highlight any key points.\n\n- Make sure come out with at least 12 posts.\n\n- Include emojis in each of the tweet.\n\n- Don't end the tweet with question, but end with an explanation.\n\n- Make sure each tweet length not more than 280 characters.\n\n- Try as much possible to fill in the length of 280 characters.\n\n- Do not start items with the same opening words.\n\n- Ensure you follow ALL these instructions when creating your output.`;

export const useMyServicesStore = defineStore({
  id: 'myServicesStore',
  state: () => ({
    part1: PART_ONE,
    part1Default: PART_ONE,

    part2: PART_TWO,
    part2Default: PART_TWO,

    part3: PART_THREE,
    part3Default: PART_THREE,
  }),
  actions: {
    async getYtDetails(url: string) {
      try {
        const result: {
          title: string;
          duration: string;
          thumbnail: string;
        } = await $fetch(`${useRuntimeConfig().public.apiUrl}/youtube/data?url=${url}`, {
          method: 'GET',
          credentials: 'include',
        });

        return result;
      } catch (err) {
        throw err;
      }
    },
    async getYtToTweets(url: string) {
      try {
        // get IP
        let ip = useMyUiStore().ip;
        if (!ip) ip = await useMyServicesStore().getUserIp();

        const result = await $fetch(`${useRuntimeConfig().public.apiUrl}/youtube/convert`, {
          method: 'POST',
          credentials: 'include',
          body: {
            url,
            identity: this.part1,
            steps: this.part2,
            formats: this.part3,
            ip,
          },
        });

        // update daily quotas in parallel
        useMyUiStore().checkDailyQuota();

        return result;
      } catch (err) {
        throw err;
      }
    },
    async getUserIp() {
      const result: { ip: string } = await $fetch('https://api.ipify.org/?format=json');
      return result.ip;
    },
    async getStoreCheckoutUrl(productPriceId: string) {
      try {
        const result = await $fetch(`${useRuntimeConfig().public.apiUrl}/orders/get-checkout-url/${productPriceId}`, {
          method: 'GET',
          credentials: 'include',
        });

        return result;
      } catch (err) {
        console.error(err);
      }
    },
  },
  persist: {
    pick: ['part1', 'part2', 'part3'],
  },
});
